
  .name {
    background-color: #eff0f6;
    height: 230px;
    padding: 20px;
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .imgCtr {
    background-color: #00c6c0;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img { height: 260px; width: 494px; object-fit: contain }