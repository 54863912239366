.back {
    top: 50px;
   left: 50px;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;

  }

  .card {
        background-color: #eff0f6;
        border-radius: 8px;
        padding: 20px 26px;
        display: flex;
        width: 360px;
        align-items: center;
        cursor: pointer;
  }