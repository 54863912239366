.card {
    padding: 0;
    border: 0;
}

.card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: max-height 0.3s ease;
 
}

.card:hover .card-text {
    max-height: none; /* Expand to show full content on hover */
    overflow: visible;
    white-space: normal;
}

.hidden-button {
    display: none; /* Initially hidden */
    margin-top: 10px; /* Adjust as needed */
}

.card:hover .hidden-button {
    display: block; /* Show button on hover */
}

@media only screen and (max-width: 600px) {
    .card-text {
        white-space: wrap;
    }
    .hidden-button {
        display: block; 

    }
  }