.back {
    top: 50px;
   left: 50px;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
  }

  .heroImg {
    margin-top: 100px; font-weight: 600px 
  }

  .img { height: 250px; object-fit: cover; 
    width: 100%; }

.ctr { padding: 66px 144px; }

@media only screen and (max-width: 600px) {
    .ctr {
      padding: 12px;
    }
}