

  .back {
    top: 50px;
   left: 50px;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
  }

  .card {
    border-radius: 8px;
    padding: 22px 27px;
    cursor: pointer;
  }

  .txt {
    font-size: 13px;
     color: white;
  }