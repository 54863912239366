.back {
    top: 50px;
   left: 50px;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;

  }

  .imgCtr {
    background-color: #00c6c0;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }