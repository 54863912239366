.back {
    top: 50px;
   left: 50px;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
  }

  .crd {
    border-radius: 8px;
    display: flex;
    width: 300px;
    height: 175px;
    flex-direction: column;
  }

  .ctr {
    padding: 32px;
    background-color: #eff0f6;
    border-radius: 8px;
  }

  @media only screen and (max-width: 600px) {
    .crd {
      height: auto;
    }
}